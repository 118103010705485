import React from "react";
import { Row, Container, Col, Carousel } from "react-bootstrap";
import { graphql } from 'gatsby';
import styled from 'styled-components';

import { HeroSection } from '../components/common/HeroWithBg';
import Layout from "../components/layout";
import SEO from "../components/seo";

import Img from 'gatsby-image';

const OverlaySubscribe = styled(Container)`
background-color: rgba(250, 188, 61, 0.9);
  padding: 0px;
  margin: 0px;
`;

const Forma = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    width: 320px;
  }
`;

export const query = graphql`
  query {
    slikeImages: allFile(filter: {relativeDirectory: {eq: "radovi"}}) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 2000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

const Radovi = ({ data }) => {

  const imagesList = data.slikeImages.edges;

  return (
    <Layout pageInfo={{ pageName: "radovi" }}>
      <SEO title="Radovi" keywords={[`art`, `arhitektura`, `radovi u kamenu`]} />

      <HeroSection>
        Radovi
      </HeroSection>

      <Container mb-3="true">
        <p>Pogledajte slike radova u kamenu i primenje umetnosti.</p>
      </Container>
      <Container>
        <Carousel interval="3000">
          {imagesList.map((image, i) => (
            <Carousel.Item>
              <Img fluid={image.node.childImageSharp.fluid} alt="radovi" />
            </Carousel.Item>
          ))}
        </Carousel>



      </Container>

      <Container fluid className="subscribe-section no-p-m">
        <OverlaySubscribe fluid className="no-p-m text-center pt-5 pb-5 d-flex justify-content-center">
          <Row>
            <Col xs={12} md={4}>
              <h2 className="display-5 mb-2">Prijavite se na našoj listi</h2>
            </Col>
            <Col xs={12} md={8} className="d-flex">
              <form className="form-inline w-100" name="subskrajberi" method="POST" data-netlify="true" netlify-honeypot="bot-field">
                <p className="hidden sr-only">
                  <label>Don’t fill this out if you're human: <input name="bot-field" /></label>
                </p>
                <Forma className="form-group">
                  <label htmlFor="emailSubscription" className="sr-only">Email</label>
                  <input type="email" className="form-control form-control-lg w-100" id="emailSubscription" placeholder="Vaša email adresa" />
                </Forma>
                <button type="submit" className="btn btn-outline-secondary btn-lg">Prijavite se</button>
              </form>
            </Col>
          </Row>
        </OverlaySubscribe>
      </Container>

    </Layout >
  );
}

export default Radovi;

